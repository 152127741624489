<template>
  <v-container
    class="pa-0"
    style="height: 100%;">
    <v-row
      style="height: 100%;"
      class="d-flex align-content-space-between">
      <v-col
        xl="6"
        lg="6"
        md="12"
        sm="12"
        xs="12"
        cols="12"
        v-bind="autoCompleteColumnProps"
        :class="defaultClassColumn">
        <v-autocomplete
          placeholder="Selecione"
          label="Tipo de arquivo*"
          v-model="dataLoad.subject"
          v-bind="autocompleteProps"
          :items="getFileTypes"
          :rules="[rule.required]"
        />
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="12"
        sm="12"
        xs="12"
        cols="12"
        v-bind="autoCompleteColumnProps"
        :class="defaultClassColumn">
        <v-autocomplete
          placeholder="Selecione"
          :label="
            Boolean(mandatorySelected)
            && mandatorySelected.financialGroupMandatory === true
            ? 'Grupo financeiro*'
            : 'Grupo financeiro'"
          v-model="dataLoad.financialGroupId"
          v-bind="autocompleteProps"
          :items="financialGroups"
          :rules="
            Boolean(mandatorySelected)
            && mandatorySelected.financialGroupMandatory === true
              ? [rule.required]
              : []"
        />
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="12"
        sm="12"
        xs="12"
        cols="12"
        v-bind="autoCompleteColumnProps"
        :class="defaultClassColumn">
        <v-autocomplete
          placeholder="Selecione"
          :label="
            Boolean(mandatorySelected)
            && mandatorySelected.contractMandatory === true
            ? 'Contrato*'
            : 'Contrato'"
          v-model="dataLoad.contractId"
          v-bind="autocompleteProps"
          item-color="textPrimary"
          :items="contracts"
          :loading="loadingContracts"
          :rules="
            Boolean(mandatorySelected)
            && mandatorySelected.contractMandatory === true
              ? [rule.required]
              : []"
        />
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="12"
        sm="12"
        xs="12"
        cols="12"
        v-bind="autoCompleteColumnProps"
        :class="defaultClassColumn">
        <v-autocomplete
          placeholder="Selecione"
          :label="
            Boolean(mandatorySelected)
            && mandatorySelected.subContractMandatory === true
            ? 'Subcontrato*'
            : 'Subcontrato'"
          v-model="dataLoad.subContractId"
          v-bind="autocompleteProps"
          item-color="textPrimary"
          :items="subContracts"
          :loading="loadingSubContracts"
          :rules="
            Boolean(mandatorySelected)
            && mandatorySelected.subContractMandatory === true
              ? [rule.required]
              : []"
        />
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="12"
        sm="12"
        xs="12"
        cols="12"
        v-bind="autoCompleteColumnProps"
        :class="defaultClassColumn">
        <v-autocomplete
          placeholder="Selecione"
          label="Operadora*"
          v-model="dataLoad.carrierId"
          v-bind="autocompleteCarrierProps"
          :items="mandatories"
          @change="setMandatorySelected"
          :rules="[rule.required]"
        />
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="12"
        sm="12"
        xs="12"
        cols="12"
        v-bind="autoCompleteColumnProps"
        :class="defaultClassColumn">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dataLoad.competence"
              :label="
                Boolean(mandatorySelected)
                && mandatorySelected.competencyMandatory === true
                ? 'Competência*'
                : 'Competência'"
              placeholder="Selecione"
              readonly
              outlined
              dense
              hide-details
              v-bind="attrs"
              v-on="on"
              :rules="
                Boolean(mandatorySelected)
                && mandatorySelected.competencyMandatory === true
                  ? [rule.required]
                  : []"
            />
          </template>
          <v-date-picker
            @click="setCompentence"
            v-model="date"
            type="month"
            locale="pt-br"
            color="wine"
            no-title
            scrollable>
            <v-spacer />
            <v-btn
              text
              @click="cleanCompetence">
              LIMPAR
            </v-btn>
            <v-btn
              text
              @click="setCompentence">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        cols="12"
        class="py-0"
        v-show="!validateForm">
        <p
          class="error--text text-caption">
          {{ `Por favor, preencha ao menos um campo obrigatório entre ${fieldsName} e
              adicione ao menos um arquivo (máximo de ${rule.getMaxFileSizeMB()} por arquivo).`
          }}
        </p>
        <p
          class="error--text text-caption mb-0 pb-0">
          Extensão de arquivos permitida: .xls, .xlsx, .csv, .txt e .pdf`
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DataLoadMixin from '@/shared/mixins/dataLoad/dataLoadMixin';
import FinancialGroupsMixin from '@/shared/mixins/sdi/financialGroupsMixin';
import InsuranceCarriersMixin from '@/shared/mixins/sdi/insuranceCarriersMixin';
import ContractsMixin from '@/shared/mixins/sdi/contractsMixin';
import DataLoadStore from '@/store/modules/data-load/data-load-module';
import DataLoadService from '@/services-http/sdi/DataLoadService';
import Rules from '@/shared/validators/formRules';
import moment from 'moment';
import lodash from 'lodash';

export default {
  name: "FileRegisterFields",

  data: () => ({
    defaultClassColumn: '',
    autocompleteProps: {
      itemText: "name",
      itemValue: "id",
      outlined: true,
      dense: true,
      hideDetails: true,
      color: "textPrimary",
      itemColor: "textPrimary",
    },
    autocompleteCarrierProps: {
      itemText: "carrierName",
      itemValue: "carrierId",
      outlined: true,
      dense: true,
      hideDetails: true,
      color: "textPrimary",
      itemColor: "textPrimary",
    },
    autoCompleteColumnProps: {},
    menu: false,
    loadingContracts: false,
    loadingSubContracts: false,
    watcherCalledGetContract: false,
    watcherCalledGetSubContract: false,
    dataLoadService: null,

    fieldsName: "Grupo Financeiro, Contrato e Subcontrato",

    contracts: [],
    subContracts: [],
    mandatories: [],
    mandatorySelected: null,
    date: null,
    rule: null,
  }),

  watch: {
    date: {
      deep: true,
      handler(value) {
        if (value) this.setCompentence();
      },
    },
    dataLoad: {
      deep: true,
      handler(value) {
        if (value) {
          if (!this.reset) {
            DataLoadStore.setDataLoad(value);
          }
        }
      },
    },
    reset: {
      handler(value) {
        if (value) {
          DataLoadStore.cleanDataLoad();
          this.resetDataLoadObj();
        }
      },
    },
    'dataLoad.subject': {
      deep: true,
      handler(value) {
        this.getMandatory();
      }
    },
    'dataLoad.financialGroupId': {
      deep: true,
      handler(value) {
        const financialGroupId = lodash.cloneDeep(value);
        if (financialGroupId) {
          if (!this.watcherCalledGetContract) {
            this.loadContractsByFinancialGroupId(financialGroupId);
          }
        } else {
          this.contracts = [];
          this.subContracts = [];
          this.watcherCalledGetContract = false;
        }
      },
    },
    'dataLoad.contractId': {
      deep: true,
      handler(value) {
        if (value) {
          if (!this.watcherCalledGetSubContract) {
            this.loadSubContractsByContractId(value);
          }
        } else {
          this.subContracts = [];
          this.watcherCalledGetSubContract = false;
        }
      },
    }
  },

  props: {
    financialGroups: { type: Array },
    insuranceCarriers: { type: Array },
    validateForm: { type: Boolean },
    reset: {
      type: Boolean,
      default: false
    }
  },

  mixins: [
    DataLoadMixin,
    FinancialGroupsMixin,
    InsuranceCarriersMixin,
    ContractsMixin,
  ],

  methods: {
    setMandatorySelected(carrierId) {
      this.mandatorySelected = this.mandatories.find(item => item.carrierId === carrierId);
    },
    async getMandatory() {
      if (!Boolean(this.dataLoad.subject)) return;
      this.mandatories = [];
      this.dataLoad.carrierId = null;
      await this.dataLoadService.GetConfigMandatoryExtFile(this.dataLoad.subject).then(response => {
        if (response.status === 200) {
          this.mandatories = response.data
        }
      })
    },

    setCompentence() {
      if (Boolean(this.date)) {
        this.dataLoad.competence = moment(this.date, 'YYYY-MM').format('MM/YYYY');
      }
      this.menu = false;
    },

    cleanCompetence() {
      this.dataLoad.competence = null;
      this.date = null;
    },

    loadContractsByFinancialGroupId(financialGroupId) {
      return new Promise((resolve) => {
        this.getContractsByFinancialGroupId(financialGroupId).then(() => {
          resolve();
        });
      });
    },
    loadSubContractsByContractId(contractId) {
      return new Promise((resolve) => {
        this.getSubContractsByContractId(contractId).then(() => {
          resolve();
        });
      });
    },
  },

  computed: {
    getFileTypes() {
      return DataLoadStore.fileTypesData
    },
  },

  created() {
    this.rule = new Rules();
    this.dataLoadService = new DataLoadService();
  },
}
</script>