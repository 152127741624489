import DataLoadStore from '@/store/modules/data-load/data-load-module';

export default {
  data: () => ({
    dataLoad: {
      files: [],
      subject: null,
      financialGroupId: null,
      contractId: null,
      subContractId: null,
      carrierId: null,
      competence: null,
      monthReference: null,
      yearReference: null,
    },
    filterDataLoad: {
      uploadType: '',
      createdBy: '',
      carrierId: '',
      financialGroupId: '',
      contractId: '',
      subContractId: '',
      status: '',
      extProcessStatus: '',
      competence: '',
      id: '',
    },
    dataModelTypes: {
      'MOVEMENT_RECORD': 'Movimentação Cadastral',
    },
    extProcessStatus: {
      "COLLECTION_IN_PROGRESS": "Coleta em andamento",
      "WAIT_CONVERSION": "Aguardando conversão",
      "CONVERSION_IN_PROGRESS": "Conversão em andamento",
      "WAIT_TRANSFORMATION": "Aguardando transformação",
      "TRANSFORMATION_IN_PROGRESS": "Transformação em andamento",
      "FINISHED": "Finalizado",
      "PARTIALLY_FINISHED": "Finalizado com criticas",
      "INTEGRATION_IN_PROGRESS": "Integração em andamento",
      "INTEGRATION_COMPLETED": "Integração concluída",
      "INTEGRATION_ERROR": "Erro na integração",
      "INTEGRATION_RETRY": "Retentativa de integração",
      "CONVERSION_ERROR": "Erro de conversão",
      "VALIDATION_ERROR": "Erro de validação",
      "VALIDATION_IN_PROGRESS": "Validação em andamento",
      "TRANSFORMATION_ERROR": "Erro na transformação",
      "WAIT_VALIDATION": "Aguardando validação",
      "WAIT_DATA_IDENTIFICATION": "Aguardando identificação de dados",
      "DATA_IDENTIFICATION_IN_PROGRESS": "Identificando dados",
      "DATA_IDENTIFICATION_ERROR": "Erro na identificação dos dados",
      "CADASTRAL_POSITION_EMPTY": "Posição cadastral vazia",
      "WAIT_MOVEMENT_RETURN_PROCESSING": "Aguardando processamento de retorno da movimentação",
      "MOVEMENT_RETURN_PROCESSING": "Processando de retorno da movimentação",
      "WAIT_BENEFICIARY_BASE_ACTION": "Aguardando ação da base do beneficiário",
      "BENEFICIARY_BASE_ACTION": "Ação da base do beneficiário",
      "WAIT_BENEFICIARY_BASE_SYNC": "Aguardando sincronização da base do beneficiário",
      "BENEFICIARY_BASE_SYNC": "Sincronização da base do Beneficiário",
      "RULES_VALIDATION_IN_PROGRESS": "Validando regras",
      "RULES_VALIDATION_ERROR": "Erro na validação de regras",
      "WAIT_RULES_VALIDATION": "Aguardando a validação de regras",
      "WAIT_DATA_CLASSIFICATION": "Aguardando a classificação dos dados",
      "DATA_CLASSIFICATION_IN_PROGRESS": "Classificando dados"
    },
    extProcessStatusFileToReprocess: [
      'PARTIALLY_FINISHED', 'DATA_IDENTIFICATION_ERROR', 'RULES_VALIDATION_ERROR'
    ],
    extProcessStatusFileToDelete: [
      'FINISHED', 'PARTIALLY_FINISHED', 'INTEGRATION_ERROR', 'CONVERSION_ERROR',
      'VALIDATION_ERROR', 'TRANSFORMATION_ERROR', 'DATA_IDENTIFICATION_ERROR',
      'CADASTRAL_POSITION_EMPTY', 'RULES_VALIDATION_ERROR'
    ],
    extProcessStatusFileToViewCritics: [
      'PARTIALLY_FINISHED', 'VALIDATION_ERROR',
      'DATA_IDENTIFICATION_ERROR', 'RULES_VALIDATION_ERROR'
    ],
    status: {
      "DELETED": "Deletado",
      "VALID": "Processado",
      "COLLECTED": "Coletado",
      "DUPLICATED": "Duplicado",
      "CONVERTED": "Convertido",
      "INVALID": "Invalido",
      "CANCELED": "Cancelado",
    },
    statusColor: {
      "DELETED": "blue-grey lighten-2",
      "VALID": "success",
      "COLLECTED": "green lighten-1",
      "DUPLICATED": "cyan darken-2",
      "CONVERTED": "indigo lighten-1",
      "INVALID": "red darken-1",
      "CANCELED": "error",
    },
    statusIcon: {
      "DELETED": "fa fa-trash-alt",
      "VALID": "fa fa-check",
      "COLLECTED": "fa fa-check",
      "DUPLICATED": "fa fa-copy",
      "CONVERTED": "fa fa-check",
      "INVALID": "fa fa-exclamation",
      "CANCELED": "fa fa-exclamation",
    },
  }),
  methods: {
    getExtProcessStatus(status) {
      return this.extProcessStatus[status] || '—'
    },
    getStatus(status) {
      return this.status[status] || '—'
    },
    getStatusColor(extProcessStatus) {
      switch(true) {
        case
        [
          'IN_PROGRESS', 'WAIT', 'CLASSIFICATION', 'RETRY',
          'PROCESSING',
        ].some(extStatus =>
          extProcessStatus.includes(extStatus))
        ||
        [
          'RULES_VALIDATION', 'DATA_IDENTIFICATION'
        ].some(extStatus => extStatus === extProcessStatus):
          return '#FEBC2E';
        case
          ['FINISHED', 'COMPLETED'].some(extStatus => extProcessStatus.includes(extStatus)):
          return '#5DB884';
        case
        [
          'ERROR', 'EMPTY', 'FAILED',
        ].some(extStatus =>
          extProcessStatus.includes(extStatus)):
          return '#F73A3B';
      }
    },
    getStatusIcon(extProcessStatus) {
      switch(true) {
        case
        [
          'IN_PROGRESS', 'WAIT', 'CLASSIFICATION', 'RETRY',
          'PROCESSING',
        ].some(extStatus =>
          extProcessStatus.includes(extStatus))
        ||
        [
          'RULES_VALIDATION', 'DATA_IDENTIFICATION'
        ].some(extStatus => extStatus === extProcessStatus):
          return 'far fa-hourglass';
        case
          ['FINISHED', 'COMPLETED'].some(extStatus => extProcessStatus.includes(extStatus)):
          return 'fa fa-check';
        case
        [
          'ERROR', 'EMPTY', 'FAILED',
        ].some(extStatus =>
          extProcessStatus.includes(extStatus)):
          return 'fa fa-exclamation';
      }
    },
    resetDataLoadObj() {
      this.dataLoad = {
        files: [],
        subject: null,
        financialGroupId: null,
        contractId: null,
        subContractId: null,
        carrierId: null,
        competence: null,
        monthReference: null,
        yearReference: null,
      }
    },
    resetDataLoadObj() {
      this.dataLoad = {
        files: [],
        subject: null,
        financialGroupId: null,
        contractId: null,
        subContractId: null,
        carrierId: null,
        competence: null,
        monthReference: null,
        yearReference: null,
      }
    },
    getFileTypeLabel(subjectId) {
      if (this.getDataLoadFileTypes.length === 0) return '';
			const subject = this.getDataLoadFileTypes.find((el) => el.id === subjectId);
			return subject ? subject.name : '';
		},
  },
  computed: {
    getExtProcessStatusList() {
      return Object.entries(this.extProcessStatus).map(
        ([id, name]) => ({ id, name }));
    },
    getStatusList() {
      return Object.entries(this.status).map(
        ([id, name]) => ({ id, name }));
    },
    getStatusColors() {
      return Object.entries(this.statusColor).map(
        ([id, name]) => ({ id, name }));
    },
    getStatusIcons() {
      return Object.entries(this.statusIcon).map(
        ([id, name]) => ({ id, name }));
    },
    getDataLoadFileTypes() {
      return DataLoadStore.fileTypesData;
    },
  }
}
